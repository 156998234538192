import React, { useState } from "react";

import ClusteringTemplate from "../InsightList/Templates/ClusteringTemplate/ClusteringTemplate";
import SliceAndDiceTemplate from "../InsightList/Templates/SliceAndDiceTemplate/SliceAndDiceTemplate";
import { ACCESS_LEVEL, APP_TEMPLATES, FLIGHTING_CONSTANTS, routes } from "utility/constants/constants";
import CampaignApp from "../InsightList/Templates/CampaignApp/campaignApp";
import SlidingWindow from "./AppDevTools/SlidingWindow";
import BreadCrumb from "components/shared/Navigation/BreadCrumb";
import useFullscreen from "components/shared/Fullscreen/useFullscreen";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const AppSummaryClickedContext = React.createContext(null);

const App = ({ application, iframe, localURL }) => {
  const [isPanelOpen, setPanelOpen] = useState(false);
  const hasAccess = ACCESS_LEVEL.ALL_EXECUTE.includes(application.access_level);
  const [summaryClicked, setSummaryClicked] = useState(false);
  const [showSummaryButton, setShowSummaryButton] = useState(false);
  const history = useHistory();
  const [elementRef, isFullscreen, enterFullscreen, exitFullscreen] = useFullscreen();

  const handleSummaryClicked = (value) => {
    setSummaryClicked(value)
  }

  const handleShowSummaryButton = (value) => {
    setShowSummaryButton(value)
  }
  const togglePanel = () => {
    setPanelOpen(!isPanelOpen);
  };

  return (
    <AppSummaryClickedContext.Provider value={{
      summaryClicked,
      showSummaryButton,
      handleSummaryClicked,
      handleShowSummaryButton
    }}>
      <div className="lds_right_box_wrapper lds_right_po_box_wrapper notebook-open-wrapper">
        <div className="app_details_header">
          <BreadCrumb
            currentLoc={application.name}
            previousLoc={`${FLIGHTING_CONSTANTS.INSIGHTS} List`}
            goBack={() => history.push(routes.INSIGHTS_LIST)}
          />
          {hasAccess && application.container_name && (
            <button className="btn_small" onClick={togglePanel}>
              Logs
            </button>
          )}
        </div>
        {hasAccess && application.container_name &&  (
          <SlidingWindow
            isPanelOpen={isPanelOpen}
            onBackdropClicked={togglePanel}
          />
        )}
      <div
      ref={elementRef}
      className={`iframe-container2 ${isFullscreen ? 'fullscreen' : ''} ${localURL ? 'show' : 'fade'}`}
    >
      {application.app_type === APP_TEMPLATES.CLUSTER ? (
        <ClusteringTemplate application={application} />
      ) : application.app_type === APP_TEMPLATES.SLICEANDDICE ? (
        <SliceAndDiceTemplate application={application} />
      ) : application.app_type === APP_TEMPLATES.CAMPAIGN ? (
        <CampaignApp application={application} />
      ) : (
        <iframe
          src={iframe}
          frameBorder="0"
          title="notebookOpenCommit"
        ></iframe>
      )}

      <div
        className="fullscreen-toggle-button"
        onClick={isFullscreen ? exitFullscreen : enterFullscreen}
      >
        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </div>
    </div>
      </div>
    </AppSummaryClickedContext.Provider>
  );
};

export default App;
